import $ from "jquery";

export default class informationDemarcheModal {

    constructor() {

        $(document).on('click', '.demarche-info', this.onClickDemarcheInfo.bind(this));
    }

    onClickDemarcheInfo(event) {
        event.preventDefault();
        let description = $(event.currentTarget).data('description');
        let title = $(event.currentTarget).data('title');
        this.openFormOnModal(title, description);
    }

    openFormOnModal(title, description) {
        $('.main-container').append('<div id="form-temp-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="customTempIframe" aria-hidden="true"><div class="modal-dialog modal-lg"><div class="modal-content"><div class="modal-header"><h5 class="modal-title">' + title + '</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div><div class="body p-4">' + description + '</div></div></div></div>');
        $('#form-temp-modal').modal({show: true});
        $('#form-temp-modal').on('hidden.bs.modal', function (e) {
            $('#form-temp-modal').remove();
        })
    }

}
