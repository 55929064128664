import $ from "jquery";

export default class Login {

    constructor(hostUrlCleaner) {
        this.hostUrl = hostUrlCleaner.getUrlWithoutEndSlash();
        this.loginFormSelector = 'airportapi_loginform';
        $(document).on('submit', '#' + this.loginFormSelector, this.loginFormEventListener.bind(this));
        $(document).on('keyup', '#' + this.loginFormSelector + ' input', this.removeInvalidFeedback.bind(this));
    }

    loginFormEventListener(event) {
        event.preventDefault();
        let formData = new FormData(event.target);
        this.loginRequest(formData);
    }

    async loginRequest(formData) {

        let response = await fetch(this.hostUrl + "/account/loginapi",
            {
                method: "POST",
                body: formData
            });

        let jsonResponse = await response.json();

        if (jsonResponse.code === 200) {
            this.success();
        } else if (jsonResponse.code === 401) {
            this.fail();
        } else {
            this.fail();
        }
    }

    success() {
        // I would typically refresh data with VueJS here.
        location.reload();
    }

    fail() {
        this.addInvalidFeedback();
    }

    addInvalidFeedback()
    {
        $('#' + this.loginFormSelector + ' input').addClass('is-invalid');
    }

    removeInvalidFeedback()
    {
        $('#' + this.loginFormSelector + ' input').removeClass('is-invalid');
    }

}
