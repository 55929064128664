import $ from "jquery";

export default class findDemarches {

    constructor()
    {
        this.toggleSelector = 'toggle-card-icon';
        this.hiddenClass = 'card-hidden';
        this.cardCategoryClass = 'card-category';
        $(document).on('click', '.' + this.toggleSelector, this.toggleCategory.bind(this));

    }

    toggleCategory(event)
    {
        let cardElement = $(event.target).parents('.' + this.cardCategoryClass);
        let hasHiddenClass = cardElement.hasClass(this.hiddenClass);

        if(hasHiddenClass) {
            this.displayWidgets(cardElement);
        }else{
            this.hideWidgets(cardElement).bind(this);
        }
    }

    hideAllWidgets()
    {
        $('.' + this.cardCategoryClass).addClass(this.hiddenClass);
    }

    displayWidgets(cardElement)
    {
        this.hideAllWidgets();
        cardElement.removeClass(this.hiddenClass);
    }

    hideWidgets(cardElement)
    {
        cardElement.addClass(this.hiddenClass);
    }

}
