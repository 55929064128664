'esversion: 7';

import $ from 'jquery';

var boot = require('bootstrap');
var select2 = require('select2/dist/js/select2.full.min');

import 'bootstrap';
import '../scss/app.scss';
import { library, dom } from "@fortawesome/fontawesome-svg-core"
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {iframeResizerContentWindow} from 'iframe-resizer'

import Demarches from './components/demarches';
import ToggleCategories from './components/toggleCategories';
import Login from './components/login';
import InformationDemarcheModal from './components/informationDemarcheModal';
import HostUrlCleaner from './components/hostUrlCleaner';
import addressSelector from './components/addressSelector';

library.add(fas, far, fab);
dom.watch();

$(function() {
  'use strict';

  addressSelector();
  let hostUrlCleaner = new HostUrlCleaner(hostUrl);
  let demarches = new Demarches();
  let toggleCategories = new ToggleCategories();
  let login = new Login(hostUrlCleaner);
  let informationDemarcheModal = new InformationDemarcheModal();
});

const arr = [1, 2, 3];
const iAmJavascriptES6 = () => console.log(...arr);
window.iAmJavascriptES6 = iAmJavascriptES6;
