import $ from "jquery";
import {iframeResizer} from "iframe-resizer";

export default class demarches {

    constructor() {

        $('.search-demarches').select2({
            theme: "bootstrap4",
            placeholder: "Rechercher un service"
        });

        $(document).on('click', '.espace-demarche-url', this.onClickDemarche.bind(this));
        $(document).on('change', '.search-demarches', this.onSelectDemarche.bind(this));
        $(document).on('click', '.returnToMainScreen', this.returnToMainScreen.bind(this));
    }

    onSelectDemarche(event) {

        let entryType = $(event.target).find(":selected").data('entry_type');
        let href = $(event.target).val();
        let openType = $(event.target).find(":selected").data('open_type');

        this.openFormFromSettings(entryType, openType, href);

    }

    onClickDemarche(event) {

        event.preventDefault();

        let entryType = $(event.target).data('entry_type');
        let href = $(event.target).attr('href');
        let openType = $(event.target).data('open_type');

        this.openFormFromSettings(entryType, openType, href);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    /**
     * If EntryType is not a link then the urls are formatted as such "../airform/forms/" so in order to fix this we
     * remove the first 3 characters from the entryType URL "airform/forms/"
     *
     * @param entryType
     * @param openType
     * @param href
     */
    openFormFromSettings(entryType, openType, href){

        console.log(href);

        if(entryType == 'AIRFORM'){
            href = href.substring(3);
        }

        if ("SELF" === openType) {
            this.openFormOnIframe(href);
        } else if ("BLANK" === openType) {
            window.open(href, "_blank");
        } else if ("MODAL" === openType) {
            this.openFormOnModal(href);
        } else {
            this.openFormOnIframe(href);
        }
    }

    openFormOnModal(url) {
        $('.main-container').append('<div id="form-temp-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="customTempIframe" aria-hidden="true"><div class="modal-dialog modal-lg"><div class="modal-content"><iframe src="' + url + '" scrolling="yes" frameborder="0" style="position: relative; width: 100%; height: 85vh"></iframe></div></div></div>');
        $('#form-temp-modal').modal({show: true});
        $('#form-temp-modal').on('hidden.bs.modal', function (e) {
            $('#form-temp-modal').remove();
        })
    }

    openFormOnIframe(url) {
        $('.main-page-container').slideUp();
        $('.main-container').append('<div class="iframe-container m-auto py-4"><a href="#" class="returnToMainScreen py-4"><h4><i class="fas fa-arrow-circle-left"></i> Retour</h4></a><iframe src="' + url + '" scrolling="yes" frameborder="0" style="position: relative; width: 100%; height: 100vh"></iframe></div>');
        iframeResizer({}, 'iframe');
    }

    returnToMainScreen() {
        $('.main-page-container').slideDown();
        $('.iframe-container').remove();
    }

}
