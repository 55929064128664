export default class HostUrlCleaner {

    constructor(hostUrl) {
        this.hostUrl = this.cleanUrlWithoutEndSlash(hostUrl);
    }

    cleanUrlWithoutEndSlash(hostUrl){

        if(hostUrl.substring(hostUrl.length-1) == "/"){
            return hostUrl.substring(0, hostUrl.length-1);
        }
        return hostUrl;
    }

    getUrlWithoutEndSlash(){
        return this.hostUrl;
    }

    getUrlWithEndSlash(){
        return this.hostUrl + '/';
    }

}
