import $ from "jquery"
import "webpack-jquery-ui/autocomplete"

const handleGeolocationDistrict = ($districtInput) => {
  const setDistrict = (address, $districtInput) => {
    $.get('/custom/ajax/search-district', {'street': address}, function( response ) {
      let district = 'Quartier inconnu'
      if (typeof response.data !== 'undefined') {
        district = response.data
      }
      $districtInput.val(district)
    })
  }

  window._$(document).on('geolocation_updated', function (e) {
    if (typeof e.component !== 'undefined') {
      let address = e.component[0];
      if (typeof e.component[1] !== 'undefined') {
        address = address + " " + e.component[1]
      }
      else{
          let tabAddress = address.split(",")
          address = tabAddress[0]
      }
      setDistrict(address, $districtInput)
    }
  })
}

const handleFantoirDistrict = ($districtInput, $isLocalAddress, $addressFontoirForm) => {
  const $streetInput =  $('#form_ligne_address input[name*="street_name"]')
  const rollback_autocomplete_options = $streetInput.autocomplete({})
  const local_autocomplete_options = {
    source: function(request, response) {
      const street = request.term
      $.get('/custom/ajax/search-street', {street: street}, function (data) {
        if (typeof (data.data) != 'string') {
          response(data.data)
        }
      }).fail(function () {
        response(() => {return []})
      }).always(function () {
        $streetInput.removeClass('ui-autocomplete-loading')
      })
    },
    select: function (event, ui) {
      event.preventDefault()
      $(this).val(ui.item.label)
      setDirstrict(ui.item.district)
    }
  }
  const toggleStreetGroup = (animate = false) => {
    let $streetGroup = $('#address_street')
    if ('' !== $addressFontoirForm.find('input[name="address[city]"]').val()) {
      animate? $streetGroup.stop().show('slow') : $streetGroup.stop().show()
    }
    else {
      animate? $streetGroup.stop().hide('slow') : $streetGroup.stop().hide()
    }
  }
  const setDirstrict = (district) => {
    $districtInput.val(district)
  }
  const toggleCityBeziers = () => {
    const checked = $isLocalAddress.find('input[type=checkbox]').first().is(":checked")
    let $cityInput = $addressFontoirForm.find('input[name="address[city]"]')
    let $ccInput = $addressFontoirForm.find('input[name="address[zip_code]"]')

    $streetInput.val('')
    setDirstrict('')
    if (checked) {
      $cityInput.val('BEZIERS')
      $cityInput.attr('readonly', 'readonly')
      $ccInput.val('34500')
      $ccInput.attr('readonly', 'readonly')
      window.commune_identifiant = '340032'
      $streetInput.autocomplete( local_autocomplete_options)
    }
    else {
      $cityInput.val('')
      $cityInput.attr('readonly', null)
      $ccInput.val('')
      $ccInput.attr('readonly', null)
      window.commune_identifiant = ''
      $streetInput.autocomplete( "destroy" )
    }
    toggleStreetGroup(true)
  }

  $isLocalAddress.on('change', 'input[type=checkbox]', toggleCityBeziers)

  toggleStreetGroup()
}

export default () => {

  const $districtInput = $('#district_name')
  if ($districtInput.length === 1) {
    const $isLocalAddress = $('#form_ligne_isLocalAddress')
    const $addressFontoirForm = $('#form_ligne_address')
    if ($isLocalAddress.length === 1 && $addressFontoirForm.length === 1) {
      handleFantoirDistrict($districtInput, $isLocalAddress, $addressFontoirForm)
    }
    if ($('#geolocalisation-map').length === 1) {
      handleGeolocationDistrict($districtInput)
    }
  }
}
